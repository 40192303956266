import { inject, Injectable, isDevMode, Pipe, PipeTransform } from '@angular/core';
import { ROUTE_TRANSLATIONS, RouteKey } from '../app.routes';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'translateRoute',
  pure: false,
})
export class TranslateRoutePipe implements PipeTransform {
  private stateService = inject(StateService);

  public transform(value: RouteKey | `/${RouteKey}`): string {
    const activeLocale = this.stateService.getActiveLocale();

    if (!activeLocale) {
      return 'unknown-page-requested';
    }

    const key = (value.startsWith('/') ? value.slice(1) : value) as RouteKey;

    const translation = ROUTE_TRANSLATIONS[activeLocale]?.[key];

    if (isDevMode() && !translation) {
      throw new Error(
        `Invalid translation route key, got key ${key} , locale ${activeLocale} `,
      );
    }

    return translation ?? 'unknown-page-requested';
  }
}
